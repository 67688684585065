// Gatsby supports TypeScript natively!
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Image from "gatsby-image"

const techList = {
    angular: {
      "title": "Angular",
    },
    ruby: {
      "title": "Ruby",
    },
    android: {
      "title": "Android",
    },
    apple: {
      "title": "Apple",
    },
    dotnet: {
      "title": ".Net",
    },
    database: {
      "title": "Database",
    },
    html: {
      "title": "HTML",
    },
    css: {
      "title": "CSS",
    },
    js: {
      "title": "JS",
    },
    jquery: {
      "title": "jQuery",
    },
    react: {
      "title": "React",
    },
    php: {
      "title": "PHP",
    }
}
const AboutUs = ({data}) => (
  <Layout>
    <SEO title="About Us" />
    <header className="page-header">
      <div className="header-image-container tablet-and-up">
        <Image fixed={data.tabletAndUpHeaderImage.childImageSharp.fixed} />
      </div>
      <div className="header__inner-container">
        <h1>About Us</h1>
        <div>
          <p>Our values drive us to create a <span className="highlight">better experience</span> for our clients and anyone who contacts us. </p>
        </div>
        <Image className="mobile-to-tablet" fixed={data.mobileAndUpHeaderImage.childImageSharp.fixed} />
      </div>
    </header>
    <section className="content highlighted">
      <h2 className="md-mt-5">Our Service Principles</h2>
      <section className="about-us-service-principals">
        <ol className="about-us__ordered-list">
          <li>
            <p>Prasthana is a pioneering technology company that delivers innovative solutions to help its clients continuously stay ahead. We help businesses make the most of emerging opportunities and successfully execute their vision in a competitive and technology-driven marketplace.</p>
          </li>
          <li>
            <p>Prasthana fosters an entrepreneurial culture of owning responsibilities and outcomes. As a result, this has always delighted our clients with solutions which help them stay ahead in the competition curve.</p>
          </li>
          <li>
            <p>Prasthana is built on customer-centric and quality-centric services. Our cognitive approach to challenges and services delivery has helped solve business-critical issues for our clients.</p>
          </li>
          <li>
            <p>Prasthana empowers partners with technology solutions driven by a team of young and passionate engineers.</p>
          </li>
          <li>
            <p>We leverage state-of-the-art technology and best-of-breed business processes to collaborate with our clients to help them become high-performance businesses.</p>
          </li>
        </ol>
      </section>
    </section>
    <section className="content centered">
      <h2>Our Technologies</h2>
      <p>We use different Web technologies to respond to the unique need of each client.</p>
      <div className="our-tech">
        { 
          data.techImages.edges.map(({node: { id, name, childImageSharp: image}}) => <div className="tech" key={id}>
            <Image fixed={image.fixed} />
            <div className="tooltip">
              {techList[name].title}
            </div>
          </div>)
        }
      </div>
    </section>
  </Layout>
)


export const query = graphql`
  query AboutQuery {
    techImages: allFile(filter: {relativeDirectory: {eq: "tech"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(width: 134, height: 60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    },

    mobileAndUpHeaderImage: file(relativePath: {eq: "about-us@3x.png"}) {
      childImageSharp {
        fixed(width: 184, height: 118, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    tabletAndUpHeaderImage: file(relativePath: {eq: "about-us@3x.png"}) {
      childImageSharp {
        fixed(width: 375, height: 240, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default AboutUs